import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth';
import { db, storage } from '../firebase';
import { doc, getDoc, setDoc, query, collection, where, getDocs, updateDoc, deleteField } from 'firebase/firestore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
  IconButton,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  MenuItem,
  Select,
  Switch,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  ListItemText,
  ListItemIcon,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImage';
import { getNames as getCountryNames } from 'country-list';
import moment from 'moment-timezone';
import { getTimezonesForCountry } from 'countries-and-timezones';
import Verification from './Verification';
import SellerPreferences from './SellerPreferences';
import BuyerPreferences from './BuyerPreferences';
import PostageOptions from './PostageOptions';
import PublicProfile from './PublicProfile';
import ProfileDashboard from './ProfileDashboard';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import FeatureControl from '../context/FeatureControl';
import withAnalytics from '../hoc/withAnalytics';
import { backgroundScroll } from '../utils/BackgroundScroll';
import Loading from '../components/Loading';

const stripePromise = loadStripe('pk_live_PlmeKRPxrTmL1JVDgak5EJZr');

const Profile = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    country: '',
    currency: '',
    timezone: '',
    username: '',
    verificationStatus: 'Not Verified',
    sellerPreferences: {
      paymentOptions: {
        paypal: '',
        bankDeposit: {
          accountName: '',
          bsb: '',
          accountNumber: '',
          feeType: '$',
          fee: 0,
        },
        payID: {
          feeType: '%',
          fee: 0,
        },
        other: [],
        paypalFeeType: '%',
        paypalFee: 0,
        bankDepositFee: 0,
        payIDFee: 0,
      },
      postageOptions: [],
    },
    buyerPreferences: {
      preferredAddress: '',
      additionalDetails: '',
    },
    notificationPreferences: {
      emailForSelling: false,
      emailForBuying: false,
    },
  });
  const [editField, setEditField] = useState(null);
  const [newPhoto, setNewPhoto] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [openPublicProfileDialog, setOpenPublicProfileDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState(null); 
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [plans, setPlans] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [listingLimitWarning, setListingLimitWarning] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [currentListings, setCurrentListings] = useState(0); 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [checkoutOptions, setCheckoutOptions] = useState({
    clientSecret: null,
    requiresPaymentMethod: false,
  });
  const [awayStatus, setAwayStatus] = useState(false);
  const [awayDate, setAwayDate] = useState(null);

  useEffect(() => {
    const fetchAwayStatus = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setAwayStatus(data.awayStatus || false);
          setAwayDate(data.awayDate ? dayjs(data.awayDate) : null); // Use Day.js for compatibility
        }
      }
    };
    fetchAwayStatus();
  }, [currentUser]);

  const handleAwayStatusChange = async () => {
    const newStatus = !awayStatus;
    setAwayStatus(newStatus);
    await setDoc(
      doc(db, 'users', currentUser.uid),
      { awayStatus: newStatus },
      { merge: true }
    );
  };

  const handleAwayDateChange = async (date) => {
    setAwayDate(date); // `date` is a Day.js object
    await setDoc(
      doc(db, 'users', currentUser.uid),
      { awayDate: date ? date.toISOString() : null }, // Save ISO string in Firestore
      { merge: true }
    );
  };


  useEffect(() => {
    if (openCropDialog || openPublicProfileDialog || openVerificationDialog || openPlanDialog || openCheckoutDialog || openCancelDialog) {
      backgroundScroll.disableScroll(true); // Disable scroll when any dialog is open
    } else {
      backgroundScroll.disableScroll(false); // Re-enable scroll when all dialogs are closed
    }

    return () => {
      backgroundScroll.disableScroll(false); // Cleanup scroll lock on component unmount
    };
  }, [openCropDialog, openPublicProfileDialog, openVerificationDialog, openPlanDialog, openCheckoutDialog, openCancelDialog]);
  
  useEffect(() => {
    const fetchPlans = async () => {
      const docRef = doc(db, 'subscriptions', 'products');
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.plans && data.plans.length > 0) {
          setPlans(data.plans);
  
          // Set the selected plan to the user's current plan
          const currentPlan = profile.subscription?.plan || ''; // Assuming `profile.subscription.plan` holds the current plan name
          const currentPlanIndex = data.plans.findIndex((plan) => plan.name === currentPlan);
  
          if (currentPlanIndex >= 0) {
            setSelectedPlan(currentPlanIndex); // Set the current plan as selected
          } else {
            setSelectedPlan(0); // Fallback to the first plan if no match found
          }
        } else {
          console.log('No plans found in the document!');
        }
      } else {
        console.log('No subscription plans found!');
      }
    };
  
    fetchPlans();
  }, [profile.subscription]); 
  

  // Step 1: Open the plan selection dialog
  const handleOpenPlanDialog = () => {
    setOpenPlanDialog(true);
  };

  // Step 2: Fetch clientSecret based on selected plan and show the payment dialog
  const handleProceedToCheckout = async () => {
    setLoading(true);
    setOpenPlanDialog(false);  // Close plan selection dialog
  
    const selectedPlanDetails = plans[selectedPlan];

    if (selectedPlanDetails.listingLimit && currentListings > selectedPlanDetails.listingLimit) {
      setListingLimitWarning(true); // Show warning if listings exceed the limit
      return;
    }
  
    try {
      const createSubscription = httpsCallable(functions, 'createSubscription');
  
      // If the plan is already free, no need for a coupon check
      if (Number(selectedPlanDetails.price) === 0) {
        // Handle free plan subscription
        const { data } = await createSubscription({
          plan: selectedPlanDetails,
          isFreePlan: true  // Pass the flag for free plan
        });
  
        if (data.success) {
          alert('You have successfully subscribed to the free plan.');
          await fetchProfile();  // Optionally refresh the profile
        }
      } else {
        // Handle non-free plans, possibly with a coupon
        const { data } = await createSubscription({
          plan: selectedPlanDetails,
          coupon: couponCode || null,  // Pass the coupon code if available
        });
  
        if (data.success && Number(data.finalAmount) === 0) {
          // If the coupon reduces the amount to zero, no payment required
          alert('You have successfully subscribed for free using the coupon.');
          await fetchProfile();  // Optionally refresh the profile
        } else {
          // Otherwise, proceed to payment
          setClientSecret(data.clientSecret);
          setCheckoutOptions({
            clientSecret: data.clientSecret,
            requiresPaymentMethod: data.requiresPaymentMethod || false,
          });
          setLoading(false);
          setOpenCheckoutDialog(true);
        }
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      alert(`Failed to update subscription: ${error.message}`);
      setLoading(false);
    }
  };
  
  
  
  
  
  
  
  const handleCloseWarningDialog = () => {
    setListingLimitWarning(false);
  };

  const handleCloseCheckout = async () => {
    setOpenCheckoutDialog(false);
    setClientSecret(null);  // Reset clientSecret when closing dialog
    await fetchProfile();    // Refetch the profile data after closing the checkout
  };
  
  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setProfile((prev) => ({
              ...prev,
              ...userData,
            }));
            setCurrentListings(userData.listingCount || 0); // Track the user's current number of listings
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  const handleCancelSubscription = async () => {
    try {
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      await cancelSubscription();
      alert('Subscription canceled successfully');
      setOpenCancelDialog(false);
      setSubscriptionStatus('canceled');
    } catch (error) {
      console.error('Error canceling subscription:', error);
      alert('Failed to cancel subscription');
    }
  };

  const fetchProfile = async () => {
    if (currentUser) {
      const docRef = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
  
        // Ensure that sellerPreferences and paymentOptions are properly initialized
        setProfile((prev) => ({
          ...prev,
          ...data,
          sellerPreferences: {
            ...prev.sellerPreferences,
            ...data.sellerPreferences,
            paymentOptions: {
              paypal: {
                paypalID: data.sellerPreferences?.paymentOptions?.paypal?.paypalID || '',
                fee: data.sellerPreferences?.paymentOptions?.paypal?.fee || 0,
                feeType: data.sellerPreferences?.paymentOptions?.paypal?.feeType || '%',
              },
              bankDeposit: {
                accountName: data.sellerPreferences?.paymentOptions?.bankDeposit?.accountName || '',
                bsb: data.sellerPreferences?.paymentOptions?.bankDeposit?.bsb || '',
                accountNumber: data.sellerPreferences?.paymentOptions?.bankDeposit?.accountNumber || '',
                feeType: data.sellerPreferences?.paymentOptions?.bankDeposit?.feeType || '$',
                fee: data.sellerPreferences?.paymentOptions?.bankDeposit?.fee || 0,
              },
              payID: {
                payID: data.sellerPreferences?.paymentOptions?.payID?.payID || '',
                feeType: data.sellerPreferences?.paymentOptions?.payID?.feeType || '%',
                fee: data.sellerPreferences?.paymentOptions?.payID?.fee || 0,
              },
              other: data.sellerPreferences?.paymentOptions?.other || [],
            },
            postageOptions: data.sellerPreferences?.postageOptions || [],
          },
          buyerPreferences: {
            preferredContactName: data.buyerPreferences?.preferredContactName || '',
            addressLine1: data.buyerPreferences?.addressLine1 || '',
            addressLine2: data.buyerPreferences?.addressLine2 || '',
            city: data.buyerPreferences?.city || '',
            state: data.buyerPreferences?.state || '',
            zipCode: data.buyerPreferences?.zipCode || '',
            country: data.buyerPreferences?.country || '',
            additionalDetails: data.buyerPreferences?.additionalDetails || '',
          },
          notificationPreferences: {
            emailForSelling: data.notificationPreferences?.emailForSelling || false,
            emailForBuying: data.notificationPreferences?.emailForBuying || false,
            wishlist: data.notificationPreferences?.wishlist || false,
          },
        }));
  
        if (data.subscription) {
          setSubscriptionStatus(data.subscription.status);
        }
      }
      setLoading(false);
    }
  };
  
  const getAccountTypeChipLabel = () => {
    if (profile?.subscription?.plan) {
      const planName = profile.subscription.plan.toUpperCase();
      if (subscriptionStatus === 'active') {
        return `${planName}`;
      }
      if (subscriptionStatus === 'incomplete') {
        return `${planName} (INCOMPLETE)`;
      }
      return `${planName}`;
    }
    return "FREE";
  };
  
 

  const countries = getCountryNames();
  const timezones = profile.country
    ? getTimezonesForCountry(profile.country)?.map(tz => tz.name) || []
    : moment.tz.names();

    useEffect(() => {
      fetchProfile();
    }, [currentUser]);
    
    

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditClick = (field) => {
    setEditField(field);
  };

  const handleCancelClick = () => {
    setEditField(null);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
  
    // Check if the username already exists
    if (editField === 'username' && profile.username) {
      const usernamesQuery = query(
        collection(db, 'users'),
        where('username', '==', profile.username)
      );
      const usernamesSnapshot = await getDocs(usernamesQuery);
  
      if (!usernamesSnapshot.empty && usernamesSnapshot.docs[0].id !== currentUser.uid) {
        alert('Username already exists. Please choose another one.');
        return;
      }
    }
  
    // Check if the email already exists
    if (editField === 'email' && profile.email) {
      const emailsQuery = query(
        collection(db, 'users'),
        where('email', '==', profile.email)
      );
      const emailsSnapshot = await getDocs(emailsQuery);
  
      if (!emailsSnapshot.empty && emailsSnapshot.docs[0].id !== currentUser.uid) {
        alert('Email already exists. Please choose another one.');
        return;
      }
    }
  
    // Save the updated profile data
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, profile, { merge: true });
    setEditField(null);
    alert('Profile updated successfully');
  };
  

  const handleSaveSellerPreferences = async () => {
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, { sellerPreferences: profile.sellerPreferences }, { merge: true });
    alert('Seller preferences updated successfully');
  };

  const handleSavePostageOptions = async () => {
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, { 'sellerPreferences.postageOptions': profile.sellerPreferences.postageOptions }, { merge: true });
    alert('Postage options updated successfully');
  };

  const handleSaveBuyerPreferences = async () => {
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, { buyerPreferences: profile.buyerPreferences }, { merge: true });
    alert('Buyer preferences updated successfully');
  };

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setNewPhoto(URL.createObjectURL(e.target.files[0]));
      setOpenCropDialog(true);
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handlePhotoUpload = async () => {
    const croppedImage = await getCroppedImg(newPhoto, croppedAreaPixels);
    const blob = await fetch(croppedImage).then((res) => res.blob());
    const storageRef = ref(storage, `users/${currentUser.uid}/profile.jpg`);
    await uploadBytes(storageRef, blob);
    const photoURL = await getDownloadURL(storageRef);
    setProfile((prev) => ({
      ...prev,
      photoURL,
    }));
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, { photoURL }, { merge: true });
    setNewPhoto(null);
    setOpenCropDialog(false);
  };

  const handleVerificationComplete = async (status) => {
    setProfile((prev) => ({
      ...prev,
      verificationStatus: status,
    }));
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, { verificationStatus: status }, { merge: true });
    setVerificationMessage('Verification submitted successfully.');
    setTimeout(() => setVerificationMessage(''), 3000);
  };
  

  const handlePostageOptionChange = (index, field, value) => {
    const updatedPostageOptions = [...profile.sellerPreferences.postageOptions];
    updatedPostageOptions[index] = {
      ...updatedPostageOptions[index],
      [field]: value,
    };
    setProfile(prev => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        postageOptions: updatedPostageOptions,
      },
    }));
  };

  const addOtherField = () => {
    setProfile((prev) => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        paymentOptions: {
          ...prev.sellerPreferences.paymentOptions,
          other: [...prev.sellerPreferences.paymentOptions.other, { field: '', value: '', fee: 0, feeType: '%' }],
        },
      },
    }));
  };

  const addPostageOption = (newOption) => {
    const updatedPostageOptions = [
      ...profile.sellerPreferences.postageOptions,
      newOption,
    ];
    setProfile(prev => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        postageOptions: updatedPostageOptions,
      },
    }));
    // Save to database
    savePostageOptions(updatedPostageOptions);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteAllListings = async () => {
    if (!currentUser) {
      alert('You must be logged in to delete listings.');
      return;
    }
  
    setLoadingDelete(true);
  
    try {
      // Fetch all sets
      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Get set name
      }));
  
      // Fetch all listings for the current user and delete them
      const deletePromises = setsData.map(async (set) => {
        const cardsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards'));
  
        const cardDeletePromises = cardsSnapshot.docs.map(async (cardDoc) => {
          const cardId = cardDoc.id;
  
          const listingsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards', cardId, 'listings'));
          const userListings = listingsSnapshot.docs.filter(doc => doc.id === currentUser.uid);
  
          const listingDeletePromises = userListings.map(listingDoc =>
            updateDoc(listingDoc.ref, {
              // Remove all conditions and listings for this user
              ...Object.keys(listingDoc.data()).reduce((acc, key) => {
                acc[key] = deleteField();
                return acc;
              }, {})
            })
          );
  
          // Wait for all listings of this card to be deleted
          await Promise.all(listingDeletePromises);
        });
  
        // Wait for all cards of this set to be processed
        await Promise.all(cardDeletePromises);
      });
  
      // Wait for all sets to be processed
      await Promise.all(deletePromises);
  
      alert('All listings have been deleted successfully');
      setRefreshTrigger(prev => !prev);
      fetchProfile();
    } catch (error) {
      console.error('Error deleting listings:', error.message);
      alert(`Failed to delete listings: ${error.message}`);
    } finally {
      setLoadingDelete(false);
      setOpenDeleteDialog(false);
    }
  };
  
  
  
  
  

  const handleNotificationPreferenceChange = async (e, preferenceName) => {
    const { checked } = e.target;
  
    // Update profile state
    setProfile(prevProfile => ({
      ...prevProfile,
      notificationPreferences: {
        ...prevProfile.notificationPreferences,
        [preferenceName]: checked
      }
    }));
  
    // Update Firestore immediately
    try {
      const docRef = doc(db, 'users', currentUser.uid);
      await updateDoc(docRef, {
        [`notificationPreferences.${preferenceName}`]: checked,
      });
      console.log('Notification preference updated successfully');
    } catch (error) {
      console.error('Error updating notification preference:', error);
    }
  };
  
  

  const handleSaveNotificationPreferences = async () => {
    const docRef = doc(db, 'users', currentUser.uid);
  
    try {
      await setDoc(docRef, { notificationPreferences: profile.notificationPreferences }, { merge: true });
      alert('Notification preferences updated successfully');
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      alert('Failed to update notification preferences.');
    }
  };
  

  const removePostageOption = (index) => {
    const updatedPostageOptions = profile.sellerPreferences.postageOptions.filter((_, i) => i !== index);
    setProfile(prev => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        postageOptions: updatedPostageOptions,
      },
    }));
    // Save to database
    savePostageOptions(updatedPostageOptions);
  };

  const savePostageOptions = async (postageOptions) => {
    const docRef = doc(db, 'users', currentUser.uid);
    await setDoc(docRef, {
      sellerPreferences: {
        postageOptions,
      },
    }, { merge: true });
  };

  const handleOtherFieldChange = (index, key, value) => {
    const updatedOtherOptions = [...profile.sellerPreferences.paymentOptions.other];
    updatedOtherOptions[index][key] = value;
    setProfile((prev) => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        paymentOptions: {
          ...prev.sellerPreferences.paymentOptions,
          other: updatedOtherOptions,
        },
      },
    }));
  };

  const handleBuyerPreferenceChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      buyerPreferences: {
        ...prev.buyerPreferences,
        [name]: value,
      },
    }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    
    <Container maxWidth="md">
      
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Avatar
          src={profile.photoURL || profile.firstName}
          alt={profile.firstName}
          sx={{ width: 120, height: 120, mb: 2 }}
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="upload-photo"
          type="file"
          onChange={handlePhotoChange}
        />
        <label htmlFor="upload-photo">
          <Button variant="contained" color="primary" component="span">
            {profile.photoURL ? "Change Photo" : "Upload Photo"}
          </Button>
        </label>
        <Typography variant="body2" color="textSecondary">
          Ideal size: 500x500 pixels
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenPublicProfileDialog(true)}
        >
          View Public Profile
        </Button>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
  <Card sx={{ maxWidth: 400, margin: '0 auto', mt: 4, mb: 4, boxShadow: 3 }}>
    <CardContent>
      <Typography variant="h6" textAlign="center" gutterBottom>
        Profile Status
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography
          variant="body1"
          sx={{
            color: awayStatus ? 'red' : 'green', // Red for Away, Green for Available
            fontWeight: 'bold', // Make text bold for emphasis
          }}
        >
          {awayStatus
            ? `Away till ${
                awayDate ? awayDate.toDate().toLocaleDateString() : '...'
              }`
            : 'Available'}
        </Typography>
        <Switch
          checked={awayStatus}
          onChange={handleAwayStatusChange}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: awayStatus ? 'red' : 'green', // Set switch thumb color
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: awayStatus ? 'red' : 'green', // Set track color
            },
          }}
        />
        {awayStatus && (
          <DatePicker
            label="Select Away Till Date"
            value={awayDate}
            minDate={dayjs()} // Prevent selecting past dates
            onChange={handleAwayDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      </Box>
    </CardContent>
  </Card>
</LocalizationProvider>



      

{/* Account Information Section */}
<Paper elevation={3} sx={{ padding: 3, mb: 4 }}>
  <Typography variant="h6" gutterBottom>
    Account Information
  </Typography>

  {/* Account Type and Subscribe to Premium Button */}
  <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
  <Grid item xs={12} sm={4}>
    <Typography variant="body1">Account Type:</Typography>
  </Grid>
  <Grid item xs={12} sm={8}>
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }}>
      
      <Chip
        label={getAccountTypeChipLabel()}
        color={subscriptionStatus === 'active' ? 'success' : subscriptionStatus === 'incomplete' ? 'warning' : 'default'}
        sx={{
          textTransform: 'uppercase',
          width: '150px', // Fixed width for chip
          fontSize: getAccountTypeChipLabel().length > 10 ? '0.65rem' : '0.85rem', // Dynamically adjust font size
          whiteSpace: 'nowrap', // Prevent text wrapping
          overflow: 'hidden', // Hide overflow text
          textOverflow: 'ellipsis', // Ellipsis for overflowing text
        }}
      />
      <Button
        variant="text"
        color="primary"
        onClick={handleOpenPlanDialog}
        sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }} // Margin adjustments for mobile and desktop
      >
        Change Plan
      </Button>
    </Box>
  </Grid>
</Grid>


  {/* Verification Status and Verify Account Button */}
  <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
    <Grid item xs={12} sm={4}>
      <Typography variant="body1">Verification Status:</Typography>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }}>
        
        <Chip
          label={profile.verificationStatus === "Verified" ? "VERIFIED" : "NOT VERIFIED"}
          color={profile.verificationStatus === "Verified" ? "success" : "error"}
          sx={{ textTransform: 'uppercase', width: '150px' }} // Fixed width for chip
        />
        
        {profile.verificationStatus !== "Verified" && (
          <FeatureControl requiredFeatures={["Verified Account"]}>
          <Button
            variant="text"
            color="error"
            onClick={() => setOpenVerificationDialog(true)}
            sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }} // Margin adjustments for mobile and desktop
          >
            Verify Account
          </Button>
          </FeatureControl>
        )}
        
      </Box>
    </Grid>
  </Grid>

  {/* Subscription Status and Cancel Premium Button */}
  {subscriptionStatus && (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1">Subscription Status:</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }}>
          
          <Chip
            label={subscriptionStatus.toUpperCase()} // Display subscription status in uppercase
            color={
              subscriptionStatus === 'active' ? 'success' :
              subscriptionStatus === 'incomplete' ? 'warning' :
              subscriptionStatus === 'canceled' ? 'error' :
              'default'
            } // Handle color based on subscription status
            sx={{ textTransform: 'uppercase', width: '150px' }} // Fixed width for chip
          />
          {/*{(subscriptionStatus === 'active' || subscriptionStatus === 'incomplete') && (
            <Button
              variant="text"
              color="error"
              onClick={() => setOpenCancelDialog(true)}
              sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }} // Margin right for desktop and margin bottom for mobile
            >
              Cancel Subscription
            </Button>
          )}*/}
        </Box>
      </Grid>
    </Grid>
  )}
</Paper>





      <ProfileDashboard refreshTrigger={refreshTrigger} />
      <Button variant="text" color="error" onClick={handleOpenDeleteDialog}>
        Delete All Listings
      </Button>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {["firstName", "lastName", "email", "phoneNumber", "username"].map(
              (field) => (
                <Grid item xs={12} sm={6} key={field}>
                  <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {getFieldLabel(field)}
                    </Typography>
                    {editField === field ? (
                      <Box display="flex" alignItems="center">
                        <TextField
                          name={field}
                          value={profile[field]}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        />
                        <IconButton onClick={handleSaveClick} color="primary">
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          onClick={handleCancelClick}
                          color="secondary"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography>{profile[field]}</Typography>
                        <IconButton onClick={() => handleEditClick(field)}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              )
            )}
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Country
                </Typography>
                {editField === "country" ? (
                  <Box display="flex" alignItems="center">
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Country</InputLabel>
                      <Select
                        name="country"
                        value={profile.country}
                        onChange={handleChange}
                        label="Country"
                      >
                        {countries.map((country) => (
                          <MenuItem key={country} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton onClick={handleSaveClick} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} color="secondary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{profile.country}</Typography>
                    <IconButton onClick={() => handleEditClick("country")}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Currency
                </Typography>
                {editField === "currency" ? (
                  <Box display="flex" alignItems="center">
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Currency</InputLabel>
                      <Select
                        name="currency"
                        value={profile.currency}
                        onChange={handleChange}
                        label="Currency"
                      >
                        <MenuItem value="AUD">AUD</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                        <MenuItem value="JPY">JPY</MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton onClick={handleSaveClick} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} color="secondary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{profile.currency}</Typography>
                    <IconButton onClick={() => handleEditClick("currency")}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Timezone
                </Typography>
                {editField === "timezone" ? (
                  <Box display="flex" alignItems="center">
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Timezone</InputLabel>
                      <Select
                        name="timezone"
                        value={profile.timezone}
                        onChange={handleChange}
                        label="Timezone"
                      >
                        {timezones.map((timezone) => (
                          <MenuItem key={timezone} value={timezone}>
                            {timezone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton onClick={handleSaveClick} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} color="secondary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{profile.timezone}</Typography>
                    <IconButton onClick={() => handleEditClick("timezone")}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Seller Preferences</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SellerPreferences
            profile={profile}
            setProfile={setProfile}
            handleSaveSellerPreferences={handleSaveSellerPreferences}
            addOtherField={addOtherField}
            handleOtherFieldChange={handleOtherFieldChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Postage Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PostageOptions
            profile={profile}
            handlePostageOptionChange={handlePostageOptionChange}
            handleSavePostageOptions={handleSavePostageOptions}
            addPostageOption={addPostageOption}
            removePostageOption={removePostageOption}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleAccordionChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Buyer Preferences</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BuyerPreferences
            profile={profile}
            handleBuyerPreferenceChange={handleBuyerPreferenceChange}
            handleSaveBuyerPreferences={handleSaveBuyerPreferences}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleAccordionChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          
          <Typography>Notifications</Typography>
        </AccordionSummary>
        <FeatureControl requiredFeatures={["Email Notifications"]}>
        <AccordionDetails>
        <Container maxWidth="md">
  <Box display="flex" flexDirection="column" gap={2}>
    <FormControlLabel
      control={
        <Switch
          checked={!!profile.notificationPreferences.emailForSelling} // Ensure it's a boolean
          onChange={(e) =>
            handleNotificationPreferenceChange(e, "emailForSelling")
          }
          name="emailForSelling"
          color="success"
        />
      }
      label="Emails for Sales status changes"
    />
    <FormControlLabel
      control={
        <Switch
          checked={!!profile.notificationPreferences.emailForBuying} // Ensure it's a boolean
          onChange={(e) =>
            handleNotificationPreferenceChange(e, "emailForBuying")
          }
          name="emailForBuying"
          color="success"
        />
      }
      label="Emails for Purchase status changes"
    />
    <FormControlLabel
      control={
        <Switch
          checked={!!profile.notificationPreferences.wishlist} // Ensure it's a boolean
          onChange={(e) => handleNotificationPreferenceChange(e, "wishlist")}
          name="wishlist"
          color="success"
        />
      }
      label="Daily email for listings matching your wishlist"
    />
  </Box>
</Container>

        </AccordionDetails>
        </FeatureControl>
      </Accordion>
      <Dialog open={openCropDialog} onClose={() => setOpenCropDialog(false)} fullScreen={isMobile}>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <Box position="relative" width="100%" height={400}>
            <Cropper
              image={newPhoto}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={handleCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="body1">Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCropDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePhotoUpload} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPublicProfileDialog}
        onClose={() => setOpenPublicProfileDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Public Profile</DialogTitle>
        <DialogContent>
          <PublicProfile userId={currentUser.uid} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPublicProfileDialog(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Step 1: Plan selection dialog */}
<Dialog open={openPlanDialog} onClose={() => setOpenPlanDialog(false)} maxWidth="md" fullWidth fullScreen={isMobile}>
  <DialogTitle>Select a Plan</DialogTitle>
  <DialogContent>
    <List>
      {plans && plans.length > 0 ? (
        plans.map((plan, planIndex) => {
          const isCurrentPlan = profile.subscription?.plan === plan.name; // Assuming `profile.subscription.plan` holds the current plan name
          return (
            <ListItem
              key={planIndex}
              button
              onClick={() => setSelectedPlan(planIndex)}
              sx={{
                border: selectedPlan === planIndex ? '4px solid #3f51b5' : '4px solid #e0e0e0',
                backgroundColor: selectedPlan === planIndex ? '#f0f4ff' : '#fff',
                marginBottom: 1,
                borderRadius: 1,
                padding: 2,
                transition: 'background-color 0.3s, border 0.3s', // Smooth transition on hover/select
                '&:hover': {
                  backgroundColor: selectedPlan === planIndex ? '#e0ebff' : '#f9f9f9',
                  border: '4px solid #F8CB45',
                },
              }}
            >
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {plan.name} - {`$${plan.price || "N/A"} ${plan.billingFrequency || "N/A"}`}
                    </Typography>
                    {isCurrentPlan && (
                      <Chip
                        label="Current Plan"
                        color="success"
                        size="small"
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <>
                    <List dense>
                      {plan.features && plan.features.length > 0 ? (
                        plan.features.map((feature, index) => (
                          <ListItem key={index} disableGutters>
                            <ListItemIcon sx={{ minWidth: 30 }}>
                              <CheckCircleIcon fontSize="small" color="success" />
                            </ListItemIcon>
                            <ListItemText primary={feature.name} />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText primary="No features listed" />
                        </ListItem>
                      )}
                    </List>
                    <Typography variant="caption" color="textSecondary">
                      {plan.description || 'No description available'}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })
      ) : (
        <Typography>No subscription plans available.</Typography>
      )}
    </List>

    {/* Coupon code input field */}
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Coupon Code"
        variant="outlined"
        fullWidth
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        placeholder="Enter coupon code"
      />
    </Box>

    {/* Message if coupon is applied */}
    {couponCode && (
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="textSecondary">
          If payment is required after the coupon is applied, the payment screen will be presented.
        </Typography>
      </Box>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenPlanDialog(false)} color="secondary">
      Cancel
    </Button>
    <Button
      onClick={handleProceedToCheckout}
      color="primary"
      disabled={plans.length === 0} // Disable if no plans available
    >
      {couponCode ? 'Apply Coupon' : Number(plans[selectedPlan]?.price) === 0 ? 'Select Free Plan' : 'Proceed to Payment'}
    </Button>
  </DialogActions>
</Dialog>







     {/* Step 2: Payment form dialog */}
<Dialog open={openCheckoutDialog} onClose={handleCloseCheckout} maxWidth="sm" fullWidth fullScreen={isMobile}>
  <DialogContent>
    {checkoutOptions?.clientSecret ? (
      <Elements stripe={stripePromise} options={{ clientSecret: checkoutOptions.clientSecret }}>
        <CheckoutForm 
          clientSecret={checkoutOptions.clientSecret} 
          selectedPlan={plans[selectedPlan]} 
          requiresPaymentMethod={checkoutOptions.requiresPaymentMethod} 
          onApplyCoupon={(code) => setCouponCode(code)}
        />
      </Elements>
    ) : (
      // No payment required, show success message
      <Box textAlign="center">
        <Typography variant="h6" color="success.main">
          Subscription Successful!
        </Typography>
        <Typography variant="body1">
          You have successfully subscribed to the {plans[selectedPlan]?.name} plan.
        </Typography>
      </Box>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseCheckout} color="secondary">
      Close
    </Button>
  </DialogActions>
</Dialog>




{/* Cancel Subscription Confirmation Dialog */}
<Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
  <DialogTitle>Cancel Subscription</DialogTitle>
  <DialogContent>
    <Typography>Are you sure you want to cancel your subscription? Changes will be effective immediately.</Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenCancelDialog(false)} color="primary">
      No, Keep Subscription
    </Button>
    <Button onClick={handleCancelSubscription} color="secondary">
      Yes, Cancel Subscription
    </Button>
  </DialogActions>
</Dialog>

<Dialog
  open={openDeleteDialog}
  onClose={loadingDelete ? null : handleCloseDeleteDialog} // Prevent close when deleting
  aria-labelledby="confirm-delete-title"
  disableEscapeKeyDown={loadingDelete} // Prevent closing with Esc key while deleting
>
  <DialogTitle id="confirm-delete-title">Delete All Listings</DialogTitle>
  <DialogContent>
    <Typography>Are you sure you want to delete all your listings? This action cannot be undone.</Typography>
  </DialogContent>
  <DialogActions>
    <Button
      onClick={handleCloseDeleteDialog}
      color="primary"
      disabled={loadingDelete} // Disable cancel button while deleting
    >
      Cancel
    </Button>
    <Button
      onClick={handleDeleteAllListings}
      color="secondary"
      disabled={loadingDelete}
    >
      {loadingDelete ? 'Deleting...Do not close the browser' : 'Delete All'}
    </Button>
  </DialogActions>
</Dialog>

<Dialog open={listingLimitWarning} onClose={handleCloseWarningDialog}>
        <DialogTitle>Too Many Listings</DialogTitle>
        <DialogContent>
          <Typography>
            You have more listings than the selected plan allows. Please delete some listings to match the plan limit before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarningDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>


      <Verification
        open={openVerificationDialog}
        onClose={() => setOpenVerificationDialog(false)}
        onVerified={(status) => handleVerificationComplete(status)}
      />
    </Container>
  );
};

const getFieldLabel = (field) => {
  switch (field) {
    case 'firstName':
      return 'First Name';
    case 'lastName':
      return 'Last Name';
    case 'phoneNumber':
      return 'Phone Number';
    case 'username':
      return 'Username';
    default:
      return field.charAt(0).toUpperCase() + field.slice(1);
  }
};

export default withAnalytics(Profile);
