import React, { useState, useCallback } from "react";
import {
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Avatar,
  Chip,
  Typography,
  Stack,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  useMediaQuery,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import { inkColorMap, rarityIcons } from "./constants";
import { db } from "../firebase";
import { useAuth } from "../auth";
import { getDocs, doc, collection, getDoc } from "firebase/firestore";
import PublicProfile from "../pages/PublicProfile";
import Loading from '../components/Loading';
import addToCart from "../pages/addToCart";

const CardList = ({ cards, convertPrice, setId, id, userId  }) => {
  const [expandedCard, setExpandedCard] = useState(null);
  const [listings, setListings] = useState([]);
  const [usernames, setUsernames] = useState({});
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchListings = useCallback(async (setId, cardId) => {
    try {
      const listingsSnapshot = await getDocs(
        collection(db, "sets", setId, "cards", cardId, "listings")
      );
      const listingsData = listingsSnapshot.docs.flatMap((doc) =>
        Object.entries(doc.data())
          .filter(([_, data]) => !userId || doc.id === userId) // Filter by userId
          .map(([condition, data]) => ({
            ...data,
            docName: doc.id,
            condition,
          }))
      );
  
      // Extract unique user IDs
      const userIds = [...new Set(listingsData.map((listing) => listing.userId))];
  
      // Fetch usernames and away statuses in parallel
      const userPromises = userIds.map((userId) =>
        getDoc(doc(db, "users", userId)).then((userDoc) => ({
          userId,
          data: userDoc.exists()
            ? { username: userDoc.data().username, awayStatus: userDoc.data().awayStatus }
            : null,
        }))
      );
  
      const userResults = await Promise.all(userPromises);
  
      // Map user data for quick access
      const userDataMap = userResults.reduce((acc, { userId, data }) => {
        if (data) {
          acc[userId] = `${data.username}${data.awayStatus ? " (Seller Away)" : ""}`;
        }
        return acc;
      }, {});
  
      setUsernames(userDataMap);
      setListings(listingsData);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  }, [userId]);
  

  const handleAccordionChange = useCallback(
    (card) => async (event, isExpanded) => {
      setExpandedCard(isExpanded ? card.docName : null);
      if (isExpanded) {
        setLoading(true); // Start loading
        try {
          await fetchListings(card.setId, card.docName);
        } catch (error) {
          console.error("Error fetching listings:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      }
    },
    [fetchListings]
  );
  
  

  const handleAddToCart = async (listing, price, setId, card) => {
    await addToCart({ listing, price, setId, card, currentUser });
  };

  const handleOpenProfile = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  const handleCloseProfile = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  


  return (
    <List>
      {cards.map((card) => (
        <Accordion
          key={card.docName}
          expanded={expandedCard === card.docName}
          onChange={handleAccordionChange(card)}
        >
          <AccordionSummary
            expandIcon={
              !isMobile &&
              (expandedCard === card.docId ? <RemoveIcon /> : <AddIcon />)
            }
            sx={{
              "& .MuiAccordionSummary-expandIconWrapper": {
                marginLeft: "20px",
              },
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: isMobile ? 0 : 1,
                }}
              >
                <ListItemAvatar sx={{ width: isMobile ? 60 : 100 }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      className={card.type === "Foil" ? "foil-avatar" : ""}
                      variant="square"
                      src={
                        card.image_uris?.digital?.small ||
                        card.image_uris?.digital?.large
                      }
                      sx={{
                        height: isMobile ? "10%" : "20%",
                        width: "80%",
                        objectFit: "contain",
                        border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"
                          }`,
                      }}
                    />

                    {card.type === "Foil" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 4,
                          bgcolor: "#ffd700",
                          border: isMobile
                            ? "2px solid #000000"
                            : "3px solid #000000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: isMobile ? 44 : 74,
                          height: isMobile ? 8 : 10,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: isMobile ? "8px" : "10px",
                            lineHeight: 1,
                            color: "#000000",
                          }}
                        >
                          COLD FOIL
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </ListItemAvatar>

                <Stack direction="column" spacing={1} sx={{ mr: -2 }}>
                  <ListItemIcon>
                    <Avatar
                      variant="square"
                      sx={{
                        bgcolor: "#0C0A5A",
                        width: isMobile ? 30 : 40,
                        height: isMobile ? 30 : 40,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: isMobile ? "16px" : "20px",
                        }}
                      >
                        {card.collector_number}
                      </Typography>
                    </Avatar>
                  </ListItemIcon>
                  <ListItemIcon>
                    <Avatar
                      variant="square"
                      sx={{
                        bgcolor: "#0C0A5A",
                        width: isMobile ? 30 : 40,
                        height: isMobile ? 30 : 40,
                      }}
                    >
                      <img
                        src={rarityIcons[card.rarity]}
                        alt={card.rarity}
                        style={{
                          width: isMobile ? 16 : 24,
                          height: isMobile ? 16 : 24,
                        }}
                      />
                    </Avatar>
                  </ListItemIcon>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          whiteSpace: "normal", // Allow text wrapping
                          wordBreak: "break-word", // Handle long words gracefully
                        }}
                      >
                        {card.name}
                      </Typography>
                    }
                    secondary={
                      card.version && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontSize: isMobile ? "0.6rem" : "0.8rem",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {card.version}
                        </Typography>
                      )
                    }
                  />
                  {!isMobile && card.type === "Foil" && (
                    <Avatar
                      variant="square"
                      sx={{
                        width: isMobile ? 60 : 76,
                        height: isMobile ? 20 : 24,
                        bgcolor: "#ffd700",
                        border: "2px solid #000000",
                        color: "#000000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "10px", lineHeight: 1 }}
                      >
                        COLD FOIL
                      </Typography>
                    </Avatar>
                  )}
                </Stack>
              </Box>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                width: isMobile ? "35%" : "50%",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: isMobile ? "flex-start" : "flex-end",
                }}
              >
                <Chip
                  label={card.listingsCount > 0 ? `Listings: ${card.listingsCount}` : "No Listings"}
                  sx={{
                    height: "auto",
                    width: isMobile ? "100%" : "100%",
                    bgcolor: card.listingsCount > 0 ? "secondary.main" : "error.main",
                    color: "white",
                    borderRadius: "4px",
                    padding: "0.25rem",
                    fontSize: isMobile ? "0.6rem" : "0.7rem",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                  }}
                />
                <Chip
                  clickable
                  component="a"
                  href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    height: "30px",
                    width: isMobile ? "100%" : "100%",
                    borderRadius: "4px",
                    fontSize: isMobile ? "0.6rem" : "0.7rem",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    bgcolor: "green",
                    color: "white",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
              </Box>
            </Box>
          </AccordionSummary>



          <AccordionDetails>
          <>
              {isMobile && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    padding: "8px 0",
                  }}
                >
                  Rotate your device or try Grid mode for a better view.
                </Typography>
              )}
            </>
            {loading ? (
              <Box
              sx={{
                maxHeight: "100px", // Set a max height for the loading component
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden", // Hide any overflow content from Loading
                width: "100%"
              }}
            >
              <Loading />
            </Box>
            ) : (
              <>
  {isMobile ? (
    <Grid container spacing={2}>
      {listings.map((listing, index) => {
        const finalPrice = listing.autoUpdatePrice
          ? (card.price * (listing.percentage / 100)).toFixed(2)
          : listing.price;
        const displayPrice = convertPrice(finalPrice || 0);

        return (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">
                    <Link
                      onClick={() => handleOpenProfile(listing.userId)}
                      style={{ cursor: "pointer" }}
                    >
                      <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                      {usernames[listing.userId] || listing.userId}
                    </Link>
                  </Typography>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {displayPrice}
                  </Typography>
                </Box>
                <Typography variant="body2">Qty: {listing.quantity}</Typography>
                <Typography variant="body2">Condition: {listing.condition}</Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  startIcon={<ShoppingCartIcon />}
                  onClick={() => handleAddToCart(listing, finalPrice, setId, card)}
                >
                  Add to Cart
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Seller</TableCell>
          <TableCell>Condition</TableCell>
          <TableCell>Qty Available</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Add to Cart</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listings.map((listing, index) => {
          const finalPrice = listing.autoUpdatePrice
            ? (card.price * (listing.percentage / 100)).toFixed(2)
            : listing.price;
          const displayPrice = convertPrice(finalPrice || 0);

          return (
            <TableRow key={index}>
              <TableCell>
                <Link onClick={() => handleOpenProfile(listing.userId)} style={{ cursor: "pointer" }}>
                  <PersonIcon fontSize="small" /> {usernames[listing.userId] || listing.userId}
                </Link>
              </TableCell>
              <TableCell>{listing.condition}</TableCell>
              <TableCell>{listing.quantity}</TableCell>
              <TableCell>{displayPrice}</TableCell>
              <TableCell>
                <Button
                  startIcon={<ShoppingCartIcon />}
                  onClick={() => handleAddToCart(listing, finalPrice, setId, card)}
                >
                  Add to Cart
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      </Table>
    )}
  </>
)}
</AccordionDetails>


        </Accordion>
      ))}

      <Dialog
        open={open}
        onClose={handleCloseProfile}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseProfile}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>
    </List>
  );
};

export default CardList;