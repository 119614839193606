import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, onSnapshot, addDoc, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { Box, Typography, Divider, IconButton, Checkbox, Button } from '@mui/material';
import { useAuth } from '../auth';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete'; // Import a delete icon
import EditIcon from '@mui/icons-material/Edit'; // Import an edit icon
import DoneIcon from '@mui/icons-material/Done'; // Import a done icon for finishing edit mode

const MessagesList = ({ conversationId }) => {
  const [messages, setMessages] = useState([]);
  const { currentUser } = useAuth(); // Get the current user
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [selectedMessages, setSelectedMessages] = useState([]); // State to manage selected messages
  const [senderStatuses, setSenderStatuses] = useState({}); // Cache for sender statuses

  useEffect(() => {
    if (conversationId) {
      const messagesRef = collection(db, `conversations/${conversationId}/messages`);
      const q = query(messagesRef, orderBy('timestamp', 'asc')); // Order by timestamp to show messages in order

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(messagesData); // Set the messages

        // Mark unread messages as read for the current user
        messagesData.forEach((message) => {
          if (message.recipientId === currentUser.uid && !message.read) {
            const messageRef = doc(db, `conversations/${conversationId}/messages`, message.id);
            updateDoc(messageRef, { read: true });
          }
        });

        // Fetch sender statuses
        const senderIds = [...new Set(messagesData.map((msg) => msg.senderId))];
        const statuses = { ...senderStatuses }; // Use existing cache

        await Promise.all(
          senderIds.map(async (id) => {
            if (!statuses[id]) {
              const userDoc = await getDoc(doc(db, 'users', id));
              if (userDoc.exists()) {
                const userData = userDoc.data();
                statuses[id] = {
                  username: userData.username || 'Unknown',
                  awayStatus: userData.awayStatus || false,
                };
              } else {
                statuses[id] = { username: 'Unknown', awayStatus: false };
              }
            }
          })
        );

        setSenderStatuses(statuses);
      });

      return () => unsubscribe(); // Cleanup listener on unmount
    }
  }, [conversationId, currentUser, senderStatuses]);


  const toggleSelectMessage = (messageId) => {
    if (selectedMessages.includes(messageId)) {
      setSelectedMessages(selectedMessages.filter((id) => id !== messageId));
    } else {
      setSelectedMessages([...selectedMessages, messageId]);
    }
  };

  const handleDeleteMessages = async () => {
    // Move selected messages to the bin collection only if the sender is the current user
    await Promise.all(
      selectedMessages.map(async (messageId) => {
        const messageRef = doc(db, `conversations/${conversationId}/messages`, messageId);
        const messageDoc = await getDoc(messageRef);

        if (messageDoc.exists()) {
          const messageData = messageDoc.data();
          // Ensure that the current user is the sender before deleting the message
          if (messageData.senderId === currentUser.uid) {
            // Add the message to the bin collection
            await addDoc(collection(db, `conversations/${conversationId}/bin`), messageData);
            // Delete the message from the messages collection
            await deleteDoc(messageRef);
          }
        }
      })
    );
    setSelectedMessages([]); // Clear selected messages after deletion
  };

  const isNewDay = (prevTimestamp, currentTimestamp) => {
    if (!prevTimestamp || !currentTimestamp) return true; // First message or if timestamp is missing
    const prevDay = moment(prevTimestamp.toDate()).format('YYYY-MM-DD');
    const currentDay = moment(currentTimestamp.toDate()).format('YYYY-MM-DD');
    return prevDay !== currentDay;
  };

  return (
    <Box flex="1" overflow="auto" p={2} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Messages</Typography>
        {/* Toggle edit mode */}
        <IconButton onClick={() => setEditMode(!editMode)}>
          {editMode ? <DoneIcon /> : <EditIcon />}
        </IconButton>
      </Box>

      {editMode && selectedMessages.length > 0 && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteMessages}
          >
            Delete {selectedMessages.length} {selectedMessages.length === 1 ? 'Message' : 'Messages'}
          </Button>
        </Box>
      )}

{messages.length > 0 ? (
  messages.map((msg, index) => {
    const previousMsg = messages[index - 1];
    const showDateDivider = isNewDay(previousMsg?.timestamp, msg.timestamp);
    const isSelected = selectedMessages.includes(msg.id); // Check if message is selected

    // Get sender information
    const sender = senderStatuses[msg.senderId] || { username: 'Unknown', awayStatus: false };
    const senderDisplayName = `${sender.username}${sender.awayStatus ? ' (Away)' : ''}`;

    return (
      <React.Fragment key={msg.id}>
        {showDateDivider && msg.timestamp && (
          <Box mb={2} textAlign="center">
            <Divider />
            <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
              {moment(msg.timestamp.toDate()).format('MMMM Do YYYY')}
            </Typography>
            <Divider />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={msg.senderId === currentUser.uid ? 'row-reverse' : 'row'}
          alignItems="flex-start"
          mb={2}
          bgcolor={isSelected ? '#f0f0f0' : 'transparent'} // Highlight selected messages
        >
          {editMode && (
            <Checkbox
              checked={isSelected}
              onChange={() => toggleSelectMessage(msg.id)}
              sx={{ marginRight: 2 }}
            />
          )}
          <Box
            bgcolor={msg.senderId === currentUser.uid ? '#e0f7fa' : '#fff'}
            p={2}
            borderRadius="16px"
            boxShadow={1}
            maxWidth="75%"
          >
            {/* Display Sender Username and Status */}
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {senderDisplayName}
            </Typography>
            {/* Display Message Content */}
            <Typography variant="body1" gutterBottom sx={{ whiteSpace: 'pre-wrap' }}>
              {msg.message}
            </Typography>
            {/* Display Timestamp */}
            {msg.timestamp && (
              <Typography variant="caption" color="textSecondary">
                {moment(msg.timestamp.toDate()).format('h:mm A')}
              </Typography>
            )}
          </Box>
        </Box>
      </React.Fragment>
    );
  })
) : (
  <Typography variant="h6" color="textSecondary" p={3}>
    No messages yet. Start the conversation!
  </Typography>
)}
    </Box>
  );
};

export default MessagesList;
