import React, { useState, useEffect } from 'react';
import SendMessage from './SendMessage';
import MessagesList from './MessagesList';
import UserSearch from './UserSearch';
import MailIcon from '@mui/icons-material/Mail'; 
import { useAuth } from '../auth';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy, limit, getDoc, doc, getDocs } from 'firebase/firestore';
import { Box, Typography, Button, List, ListItem, Avatar, IconButton, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

// Utility function to create a unique conversation ID based on participants
const createConversationId = (userId1, userId2) => {
  return [userId1, userId2].sort().join('_');
};

const Conversation = () => {
  const { currentUser } = useAuth();
  const [recipientId, setRecipientId] = useState(null);  // State to store recipientId
  const [recipientName, setRecipientName] = useState('');  // Store recipient's username
  const [conversations, setConversations] = useState([]);  // Store list of conversations
  const [newMessage, setNewMessage] = useState(false);  // Toggle for search view
  const [recipientAvatar, setRecipientAvatar] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  // Fetch conversations for the current user from the 'conversations' collection
  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, 'conversations'),
        where('participants', 'array-contains', currentUser.uid),
        orderBy('lastUpdated', 'desc')
      );
  
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const conversationsList = await Promise.all(
          snapshot.docs.map(async (conversationDoc) => {
            const conversationData = conversationDoc.data();
  
            if (!conversationData.participants) {
              console.error('No participants found for conversation:', conversationDoc.id);
              return null;
            }
  
            const otherParticipantId = conversationData.participants.find((id) => id !== currentUser.uid);
  
            // Fetch other participant's data
            const otherParticipantDoc = await getDoc(doc(db, 'users', otherParticipantId));
            const otherParticipantData = otherParticipantDoc.data();
  
            // Fetch the most recent message
            const messageQuery = query(
              collection(db, `conversations/${conversationDoc.id}/messages`),
              orderBy('timestamp', 'desc'),
              limit(1)
            );
            const messageSnapshot = await getDocs(messageQuery);
            const lastMessage = messageSnapshot.docs.length > 0 ? messageSnapshot.docs[0].data().message : '';
  
            // Check if there are unread messages
            const unreadMessagesQuery = query(
              collection(db, `conversations/${conversationDoc.id}/messages`),
              where('recipientId', '==', currentUser.uid),
              where('read', '==', false)
            );
            const unreadMessagesSnapshot = await getDocs(unreadMessagesQuery);
            const hasUnreadMessages = !unreadMessagesSnapshot.empty;
  
            return {
              id: conversationDoc.id,
              recipientName: `${otherParticipantData?.username || 'Unknown'}${otherParticipantData?.awayStatus ? ' (Away)' : ''}`, // Append "(Away)" if the user is away
              recipientAvatar: otherParticipantData?.photoURL || '/default-avatar.png',
              lastMessage: lastMessage,
              hasUnreadMessages: hasUnreadMessages,
              participants: conversationData.participants,
              lastUpdated: conversationData.lastUpdated || null,
            };
          })
        );
  
        // Sort conversations by lastUpdated
        const sortedConversations = conversationsList
          .filter(Boolean) // Remove null values
          .sort((a, b) => b.lastUpdated?.seconds - a.lastUpdated?.seconds);
  
        setConversations(sortedConversations);
      });
  
      return () => unsubscribe();
    }
  }, [currentUser]);
  
  

  const handleNewMessage = () => {
    setNewMessage(true);  // Show user search full screen on mobile
    setRecipientId(null); // Clear recipient selection
  };

  const handleCancelNewMessage = () => {
    setNewMessage(false);  // Cancel new message
  };

  const handleUserSelect = (id, username) => {
    setRecipientId(id);  // Set recipientId when a user is selected
    setRecipientName(username);  // Set recipientName when a user is selected
    setNewMessage(false);  // Hide user search
  };

  const handleConversationSelect = (conversation) => {
    if (!conversation || !conversation.participants) {
      console.error('Conversation or participants data is missing');
      return; // Exit the function if data is missing
    }

    const otherParticipantId = conversation.participants.find(id => id !== currentUser.uid); // Get the other user's ID
    setRecipientId(otherParticipantId);  // Set the recipient as the other participant
    setRecipientName(conversation.recipientName); // Set recipient's name
    setRecipientAvatar(conversation.recipientAvatar); 
    setNewMessage(false); // Ensure we're not in new message mode anymore
    setConversations((prevConversations) =>
    prevConversations.map((conv) =>
      conv.id === conversation.id ? { ...conv, hasUnreadMessages: false } : conv
    )
  );
};

  const handleBackToList = () => {
    setRecipientId(null); // Deselect the conversation to go back to the list
    setNewMessage(false); // Ensure the new message state is reset
  };

  return (
    <Box
      display="flex"
      height="100vh"
      flexDirection={{ xs: "column", sm: "row" }}
    >
      {/* Sidebar for conversations, hide in mobile if chat is open or new message is being composed */}
      {!isMobile || (!recipientId && !newMessage) ? (
        <Box
          width={{ xs: "90%", sm: "300px" }}
          bgcolor="#f5f5f5"
          p={2}
          display="flex"
          flexDirection="column"
          alignItems={{ xs: "flex-start", sm: "flex-start" }}
          sx={{ height: "98vh" }}
        >
          <Typography variant="h6" gutterBottom>
            Messages
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewMessage}
          >
            New Message
          </Button>

          {/* List of conversations */}
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            {conversations.map((convo) => (
              <ListItem
                button
                key={convo.id}
                onClick={() => handleConversationSelect(convo)}
                sx={{
                  backgroundColor: convo.hasUnreadMessages
                    ? "#ffe0b2"
                    : "transparent",
                  border: "2px solid #000000",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  "&:hover": {
                    backgroundColor: convo.hasUnreadMessages
                      ? "#ffcc80"
                      : "#f5f5f5", // Hover effect for unread messages
                  },
                  display: "flex",
                  justifyContent: "space-between", // Make sure the icon stays on the far right
                }}
              >
                {/* Avatar and Text */}
                <Box
                  display="flex"
                  alignItems="center"
                  overflow="hidden"
                  flexGrow={1}
                >
                  <Avatar
                    alt={convo.recipientName}
                    src={convo.recipientAvatar}
                    sx={{ marginRight: 2 }}
                  />
                  <Box display="flex" flexDirection="column" overflow="hidden">
                    <Typography
                      variant="body1"
                      noWrap
                      sx={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px", // Limit width to control overflow for names
                      }}
                    >
                      {convo.recipientName || "Unknown"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", // Prevent text wrapping
                        maxWidth: "200px", // Control width for truncation
                        color: "gray", // Optional: Style for message preview
                      }}
                    >
                      {convo.lastMessage || "No messages yet"}
                    </Typography>
                  </Box>
                </Box>

                {/* Icon for unread messages, visible only when there are unread messages */}
                {convo.hasUnreadMessages && (
                  <MailIcon
                    sx={{
                      color: "darkorange", // Icon color
                      marginLeft: "auto", // Push it to the right
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      ) : null}

      {/* Main conversation area */}
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%", maxWidth: "100vw", height: "100vh" }} // Ensure full viewport height
      >
        {newMessage ? (
          <Box bgcolor="#eceff1" p={2} display="flex" alignItems="center">
            <IconButton
              onClick={handleCancelNewMessage}
              sx={{ marginRight: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <UserSearch onSelectUser={handleUserSelect} />
          </Box>
        ) : recipientId ? (
          <>
            {/* Display recipient's name at the top with back button on mobile */}
            <Box
              bgcolor="#eceff1"
              p={2}
              boxShadow={1}
              display="flex"
              alignItems="center"
            >
              {isMobile && (
                <IconButton onClick={handleBackToList} sx={{ marginRight: 2 }}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Link
                to={`/public/${recipientName}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center", // Align the avatar and text in a row
                }}
              >
                <Avatar
                  alt={recipientName}
                  src={recipientAvatar || "/default-avatar.png"}
                  sx={{ marginRight: 2 }}
                />
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  {recipientName}
                </Typography>
              </Link>
            </Box>
            {/* Make MessagesList fill the remaining space above the SendMessage input */}
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              overflow="auto"
              sx={{ paddingBottom: "8px", flexGrow: 1 }} // Flex-grow ensures it takes available space
            >
              <MessagesList
                conversationId={createConversationId(
                  currentUser.uid,
                  recipientId
                )}
                recipientName={recipientName}
                recipientId={recipientId}
              />
            </Box>
            {/* SendMessage should stay fixed at the bottom */}
            <Box sx={{ padding: "10px", borderTop: "1px solid #ddd" }}>
              <SendMessage
                conversationId={createConversationId(
                  currentUser.uid,
                  recipientId
                )}
                recipientName={recipientName}
                recipientId={recipientId}
              />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default Conversation;
